import React, { useEffect, useRef } from "react";
import gsap from "gsap";

const NameDisplay = () => {
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    // Reset animation when component mounts
    gsap.set(firstNameRef.current, {
      y: 0,
      opacity: 1,
      color: "#2d3748",
    });

    gsap.set(lastNameRef.current, {
      rotateX: 0,
      opacity: 1,
      color: "white",
      borderRadius: "50px",
      padding: "8px",
      boxShadow: "none",
      background: "red",
    });

    // Create hover animation for container
    const container = containerRef.current;

    container.addEventListener("mouseenter", () => {
      // First name animation
      gsap.to(firstNameRef.current, {
        y: -10,
        color: "#4a5568",
        opacity: 0.9,
        duration: 0.3,
        ease: "power2.out",
        color: "#4a5568",
      });

      // Last name card flip animation
      gsap.to(lastNameRef.current, {
        rotateX: 360,
        color: "white",
        opacity: 1,
        duration: 0.8,
        delay: 0.1,
        ease: "power2.inOut",
        transformPerspective: 1000,
        transformOrigin: "center center",
        boxShadow: "0px 10px 10px 0 rgba(0,0,0,0.75)",
      });
    });

    container.addEventListener("mouseleave", () => {
      // First name reset
      gsap.to(firstNameRef.current, {
        y: 0,
        color: "#2d3748",
        opacity: 1,
        duration: 0.3,
        ease: "power2.out",
      });

      // Last name reset with flip
      gsap.to(lastNameRef.current, {
        rotateX: 0,
        opacity: 1,
        duration: 0.8,
        ease: "power2.inOut",
        transformPerspective: 1000,
        transformOrigin: "center center",
      });
    });

    // Cleanup
    return () => {
      container.removeEventListener("mouseenter", () => {});
      container.removeEventListener("mouseleave", () => {});
    };
  }, []);

  return (
    <div
      ref={containerRef}
      className="flex flex-col items-center justify-center min-h-screen bg-gray-50 p-4 perspective"
    >
      <div className="text-center">
        <h1
          ref={firstNameRef}
          className="text-6xl md:text-8xl lg:text-9xl font-bold mb-2 text-gray-800"
        >
          ALFIE
        </h1>
        <div className="preserve-3d">
          <h1
            ref={lastNameRef}
            className="text-6xl md:text-8xl lg:text-9xl font-bold text-gray-800"
          >
            CHIONG
          </h1>
          <div className="mt-10">
            <h2>WEB DEVELOPER</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NameDisplay;
